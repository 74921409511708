import { useEffect, useState } from 'react'
import { IoIosArrowBack } from 'react-icons/io'
import { BsFillPersonCheckFill } from 'react-icons/bs'
import s from './EnhancedTimekeeepersContainer.scss'
import cn from 'classnames'
import APP_ACT from 'app/actions'
import { FaMoneyBill } from 'react-icons/fa'
import {
  Button,
  ButtonDropdown,
  CharLimitInput,
  DataTableWrapper,
  ModalContainer,
  Panel,
  useLoading
} from 'simple-core-ui'
import { Tabs } from './Tabs'
import { Params, SelectedBatchMeta, TimekeeperRow } from './types'
import { makeGetRequest } from 'simple-core-ui/utils/api'
import { ActionsPopover } from './ActionsPopover'
import { Updater, useImmer } from 'use-immer'
import { serializeTimekeepers } from './serializers'
import { useDispatch } from 'react-redux'
import { useColumns, useFilteredTimekeepers } from './tableDefinitions'
import { Breadcrumbs } from './Breadcrumbs'
import { makePatchRequest } from 'utils/api'
import pluralize from 'pluralize'

const initialParams = {
  pageSize: 50,
  ordering: { columnKey: 'created_date', isDesc: true },
  search: '',
  page: 1,
  category: 'pending'
}

const BULK_OPTIONS = [
  { label: 'Approve', value: 'approve' },
  { label: 'Reject', value: 'reject' },
  { label: 'Edit Effective Date(s)', value: 'edit' }
]

const EnhancedTimekeepersContainer = () => {
  const [localState, setLocalState] = useState({
    params: initialParams
  })
  const { params } = localState

  const [view, setView] = useState('rates')
  const [selectedTab, setSelectedTab] = useState(params.category)
  const [oldSelectedTab, setOldSelectedTab] = useState(params.category)
  const [oldSelectedView, setOldSelectedView] = useState('')
  const [showBatchDetails, setShowBatchDetails] = useState(false)
  const [showTkDetails, setShowTkDetails] = useState(false)
  const [selectedBatchMeta, setSelectedBatchMeta] = useState<SelectedBatchMeta | null>(null)
  const [timekeepers, setTimekeepers]: [TimekeeperRow[], Updater<TimekeeperRow[]>] = useImmer<
    TimekeeperRow[]
  >([])
  const [totalEntries, setTotalEntries] = useState(0)
  const [pendingCount, setPendingCount] = useState(0)
  const [completedCount, setCompletedCount] = useState(0)
  const [allCount, setAllCount] = useState(0)
  const [showLoadingSkeleton, setShowLoadingSkeleton] = useState(true)
  const [selectedRows, setSelectedRows] = useState<number[]>([])
  const [allRowsSelected, setAllRowsSelected] = useState(false)
  const [showRejectionReason, setShowRejectionReason] = useState(false)
  const [rejectionReason, setRejectionReason] = useState('')

  const [isLoading, withLoadingLocksGetTks] = useLoading()

  const isRatesView = view === 'rates'
  const isTkListView = view === 'list'

  const dispatch = useDispatch()

  const fetchCounts = async () => {
    try {
      const { completedBatchCount, pendingBatchCount, totalRates } = await makeGetRequest(
        '/timekeepers/tk_rates_count/'
      )

      setCompletedCount(completedBatchCount)
      setPendingCount(pendingBatchCount)
      setAllCount(totalRates)
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error
      })
    }
  }

  const fetchTimekeepers = async (
    selectedTab: string,
    tableParams: Params,
    urlParamsString: string = ''
  ) => {
    try {
      const url = isRatesView
        ? ['pending', 'completed'].includes(selectedTab) && !urlParamsString
          ? `/timekeepers/timekeeper_batch_list/`
          : `/timekeepers/timekeeper_rates_list/?${urlParamsString}`
        : '/timekeepers/timekeepers_list/'

      const urlTableParams = {
        columnKey: tableParams.ordering.columnKey,
        search: tableParams.search,
        page_number: tableParams.page,
        page_size: tableParams.pageSize,
        ...(tableParams.ordering.isDesc ? { isDesc: tableParams.ordering.isDesc } : {})
      }

      const urlParams =
        selectedTab === 'completed'
          ? { params: { batch: selectedTab, ...urlTableParams } }
          : {
              params: urlTableParams
            }

      const { rows, totalEntries } = await withLoadingLocksGetTks(makeGetRequest(url, urlParams))

      setTimekeepers(serializeTimekeepers(rows, isRatesView, selectedTab, Boolean(urlParamsString)))
      setTotalEntries(totalEntries)
      setShowLoadingSkeleton(true)
      setSelectedRows([])
      setAllRowsSelected(false)
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error
      })
    }
  }

  useEffect(() => {
    fetchTimekeepers('pending', params)
    fetchCounts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClickCell = (type: string, rowData: TimekeeperRow) => {
    if (type === 'batch_list') {
      const urlParamsString = `vendor=${rowData.vendor_id}&batch_id=${rowData.timekeeper_file_id}`
      const newParams = {
        ...initialParams,
        ordering: { columnKey: 'effective', isDesc: true }
      }
      setLocalState(state => ({
        ...state,
        params: newParams
      }))
      setSelectedBatchMeta({
        vendorId: rowData.vendor_id,
        vendorName: rowData.vendor_name,
        submittedBy: rowData.full_name,
        email: rowData.email,
        batchId: rowData.timekeeper_file_id
      })

      setShowBatchDetails(true)
      fetchTimekeepers(selectedTab, params, urlParamsString)
    }
  }

  const handleApprove = async (row?: TimekeeperRow) => {
    setShowLoadingSkeleton(false)
    try {
      const url = `/timekeepers/timekeeper_update/`

      const data = {
        status: 'approve',
        batch_id: selectedBatchMeta?.batchId,
        tk_ids: row?.id
          ? row.id
          : selectedRows.length
          ? selectedRows
          : selectedBatchMeta?.rejectionRowId,
        vendor_id: selectedBatchMeta?.vendorId
      }

      const urlParamsString = `vendor=${selectedBatchMeta?.vendorId}&batch_id=${selectedBatchMeta?.batchId}`

      const rowCount = selectedRows.length || 1

      await withLoadingLocksGetTks(makePatchRequest(url, data))
      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'Success',
          message: `${pluralize('row', rowCount, true)} successfully approved`,
          level: 'success'
        }
      })
      fetchTimekeepers(selectedTab, params, urlParamsString)
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error
      })
      setShowLoadingSkeleton(true)
      setRejectionReason('')
    }
  }

  const handleReject = async (row?: TimekeeperRow) => {
    setShowRejectionReason(true)
    setSelectedBatchMeta({
      ...(selectedBatchMeta as SelectedBatchMeta),
      rejectionRowId: row?.id || selectedRows
    })
  }

  const onConfirmRejection = async () => {
    try {
      setShowLoadingSkeleton(false)
      const url = `/timekeepers/timekeeper_update/`

      const data = {
        batch_id: selectedBatchMeta?.batchId,
        status: 'reject',
        reason: rejectionReason,
        tk_ids: selectedRows.length ? selectedRows : selectedBatchMeta?.rejectionRowId,
        vendor_id: selectedBatchMeta?.vendorId
      }

      const urlParamsString = `vendor=${selectedBatchMeta?.vendorId}&batch_id=${selectedBatchMeta?.batchId}`

      await withLoadingLocksGetTks(makePatchRequest(url, data))

      const rowCount = selectedRows.length || 1

      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'Success',
          message: `${pluralize('row', rowCount, true)} successfully approved`,
          level: 'success'
        }
      })
      fetchTimekeepers(selectedTab, params, urlParamsString)
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error
      })
    }
    setShowLoadingSkeleton(true)
    setRejectionReason('')
    setShowRejectionReason(false)
  }

  const columns = useColumns(
    selectedTab,
    isTkListView,
    handleClickCell,
    setShowTkDetails,
    showBatchDetails,
    handleApprove,
    handleReject
  )

  const filteredTks = useFilteredTimekeepers(
    timekeepers,
    selectedTab,
    isTkListView,
    showBatchDetails
  )

  useEffect(() => {
    const newParams = {
      ...initialParams,
      ordering: isRatesView
        ? selectedTab === 'all'
          ? { columnKey: 'effective', isDesc: true }
          : { columnKey: 'created_date', isDesc: true }
        : { columnKey: 'modified_date', isDesc: true },
      category: isRatesView ? selectedTab : initialParams.category
    }

    setLocalState(state => ({
      ...state,
      params: newParams
    }))

    if (isRatesView && oldSelectedView === 'list') {
      fetchTimekeepers('pending', initialParams)
      setOldSelectedView('')
    } else if (isTkListView) {
      fetchTimekeepers(selectedTab, newParams)
    }
  }, [selectedTab, view])

  const clearBulkSelection = () => {
    setSelectedRows([])
    setAllRowsSelected(false)
  }

  const onChangeTabCb = (selectedTab: string) => {
    setShowBatchDetails(false)
    setLocalState({ params: initialParams })
    setOldSelectedTab(selectedTab)
    fetchTimekeepers(selectedTab, initialParams)
    clearBulkSelection()
  }

  const changeTab = (tab: string) => {
    if (tab !== selectedTab) {
      setSelectedTab(tab)
      onChangeTabCb(tab)
    }
  }

  const selectRow = ({ id }: { id: number }) => {
    setAllRowsSelected(false)

    if (selectedRows.includes(id)) {
      setSelectedRows(prevSelectedRows => prevSelectedRows.filter(rowId => rowId !== id))
    } else {
      setSelectedRows(prevSelectedRows => [...new Set([...prevSelectedRows, id])])
    }
  }

  const selectAllRows = () => {
    setAllRowsSelected(allRowsSelected => !allRowsSelected)
    setSelectedRows(allRowsSelected ? [] : filteredTks.map(t => t.id))
  }

  const updateTable = async (params: Params) => {
    setShowLoadingSkeleton(false)
    setLocalState({
      ...localState,
      params
    })

    const urlParamsString = showBatchDetails
      ? `vendor=${selectedBatchMeta?.vendorId}&batch_id=${selectedBatchMeta?.batchId}`
      : ''

    fetchTimekeepers(selectedTab, params, urlParamsString)
  }

  const triggerBulkAction = (option: { value: string; label: string }) => {
    switch (option.value) {
      case 'approve':
        handleApprove()
        break
      case 'reject':
        handleReject()
        break
      case 'edit':
        break
      case 'default':
        break
    }
  }

  const bulkActionsButton = (
    <ButtonDropdown
      displayText={`Bulk Actions (${selectedRows.length})`}
      options={BULK_OPTIONS}
      style={{ padding: '10px', borderRadius: '2px' }}
      listStyles={{ top: '42px' }}
      listItemStyles={{ fontSize: '14px' }}
      onSelect={triggerBulkAction}
    />
  )

  const renderCustomAction = (row: TimekeeperRow) => (
    <ActionsPopover
      timekeeper={row}
      copyTk={() => {}}
      downloadTk={() => {}}
      showDownload={selectedTab !== 'all' && !isTkListView}
    />
  )

  const content = (
    <>
      <p className={s.modalText}>Are you sure you want to reject the selected rate(s)?</p>
      <p className={s.fieldTitle}>
        Reason for Rejection <span>*</span>
      </p>
      <CharLimitInput
        rows={4}
        type="textarea"
        cssClass={s.textarea}
        value={rejectionReason}
        onChangeCb={e => setRejectionReason(e.target.value)}
        dynamicCharCalculation
      />
    </>
  )

  const onCancel = () => {
    setRejectionReason('')
    setShowRejectionReason(false)
  }

  return (
    <section className={s.container}>
      <section className={s.panel}>
        <a href="/v2/administration/">
          <IoIosArrowBack />
          {' Back to Administration'}
        </a>

        <div className={cn(s.panelContainer, { [s.withShadow]: isRatesView })}>
          <div className={s.innerPanel}>
            <div className={s.header}>
              <h2 className={s.title} data-testid="title">
                Timekeepers
              </h2>
              <div className={s.tabs}>
                <span
                  className={cn({ [s.selected]: isRatesView })}
                  onClick={() => {
                    if (isTkListView) {
                      setOldSelectedView('list')
                      setView('rates')
                      setShowBatchDetails(false)
                      setSelectedBatchMeta(null)
                    }
                  }}
                >
                  <FaMoneyBill /> Rate Review
                </span>
                <span
                  className={cn({ [s.selected]: isTkListView })}
                  onClick={() => {
                    if (isRatesView) {
                      setOldSelectedView('rates')
                      setView('list')
                      setShowBatchDetails(false)
                      setSelectedBatchMeta(null)
                    }
                    setSelectedTab('pending')
                  }}
                >
                  <BsFillPersonCheckFill /> All Timekeepers
                </span>
              </div>
              {selectedTab !== 'pending' || isTkListView ? (
                <span className={s.rightActions}>
                  <Button
                    style={{ padding: '10px 15px' }}
                    key="download"
                    onClick={() => {}}
                    isPrimary
                    isOutline
                    hasNewDesign
                  >
                    Download
                  </Button>
                </span>
              ) : null}
            </div>
            {isRatesView ? (
              <Tabs
                selectedTab={selectedTab}
                setSelectedTab={changeTab}
                pendingCount={pendingCount}
                completedCount={completedCount}
                allCount={allCount}
              />
            ) : null}
            <Panel
              className={s.panel}
              bodyClassName={s.panel}
              headerClassName={s.headerClassName}
              isBodyOnly={!showBatchDetails || isTkListView}
              breadcrumbs={
                showBatchDetails && selectedBatchMeta ? (
                  <Breadcrumbs
                    items={[
                      {
                        title: 'Vendors',
                        clickCb: () => {
                          clearBulkSelection()
                          setShowBatchDetails(false)
                          setSelectedBatchMeta(null)
                          fetchTimekeepers(selectedTab, params)
                        }
                      },
                      {
                        title: selectedBatchMeta.vendorName,
                        redirectTo: `/invoices/vendors/${selectedBatchMeta.vendorId}/?fromTimekeepers=true`
                      }
                    ]}
                  />
                ) : null
              }
            >
              {showBatchDetails ? (
                <p className={s.vendorMeta}>
                  Submitted by {selectedBatchMeta?.submittedBy} ( <a>{selectedBatchMeta?.email}</a>{' '}
                  )
                </p>
              ) : null}
              <DataTableWrapper
                alwaysShowLoadingSkeleton={showLoadingSkeleton}
                hasActions={!showBatchDetails}
                alwaysShowActions
                isLoading={isLoading}
                remotePagination
                params={params}
                categories={[]}
                rows={filteredTks}
                totalEntries={totalEntries}
                columns={columns}
                customAction={renderCustomAction}
                updateTable={updateTable}
                entryLanguage={
                  isTkListView
                    ? { singular: 'Timekeeper', plural: 'Timekeepers' }
                    : selectedTab === 'all'
                    ? {
                        singular: 'Rate',
                        plural: 'Rates'
                      }
                    : {
                        singular: 'Batch',
                        plural: 'Batches'
                      }
                }
                hasTooltip
                panelStyles={{ boxShadow: 'none', padding: 0 }}
                bulkActions={bulkActionsButton}
                selectAllRows={showBatchDetails ? selectAllRows : undefined}
                selectRow={showBatchDetails ? selectRow : undefined}
                selectedRows={new Set(selectedRows)}
                allRowsSelected={allRowsSelected}
              />
            </Panel>
          </div>
        </div>
        {showRejectionReason && (
          <ModalContainer
            contentClassName={s.modal}
            title="Reject Timekeeper Rate"
            content={content}
            confirmText="Yes, Reject"
            size="sm"
            hasNewButtons
            contentStyle={{ padding: '10px 24px 30px', minHeight: 'auto' }}
            confirmCb={onConfirmRejection}
            cancelCb={onCancel}
          />
        )}
      </section>
    </section>
  )
}

export default EnhancedTimekeepersContainer
