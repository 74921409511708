import moment from 'moment'
import get from 'lodash/get'
import { isValid, parse } from 'date-fns'

const BUDGET_TYPES = {
  PHASE: 1,
  MONTHLY: 2,
  LIFE_OF_MATTER: 3,
  QUARTERLY: 4
}

const _getErrObj = errors => {
  return { isError: !!Object.keys(errors).length, errors }
}

const INVALID_FIELD_CHECKS = {
  budgetStartDate: formState =>
    moment(formState.budgetEndDate, 'MM/YYYY').isBefore(
      moment(formState.budgetStartDate, 'MM/YYYY')
    ),

  budgetEndDate: formState =>
    moment(formState.budgetStartDate, 'MM/YYYY').isAfter(
      moment(formState.budgetEndDate, 'MM/YYYY')
    ),

  budgetDueDate: formState =>
    !isValid(parse(formState.budgetDueDate, 'yyyy-MM-dd', new Date())) &&
    formState.budgetDueDateInput?.trim().length > 0,

  budgetDescription: formState => formState.budgetDescription?.length > 250
}

const _applyErrors = (formState, requiredFields, errors, scope = null) => {
  Object.entries(INVALID_FIELD_CHECKS).forEach(([field, isInvalid]) => {
    if (field in formState && isInvalid(formState)) {
      errors[field] = true
    }
  })

  const filteredFields =
    scope === 'matter' ? requiredFields.filter(field => field !== 'matterId') : requiredFields

  filteredFields.forEach(key => {
    if (!formState[key]) {
      errors[key] = true
    }
  })
}

export const validateBudgetRequest = formState => {
  let errors = {}
  let requiredFields = ['collaborators', 'budgetName', 'budgetType']

  const { PHASE, MONTHLY, LIFE_OF_MATTER, QUARTERLY } = BUDGET_TYPES

  const budgetType = get(formState, 'budgetType.value')

  if (budgetType === LIFE_OF_MATTER || budgetType === MONTHLY) {
    requiredFields = requiredFields.concat(['budgetStartDate', 'budgetEndDate'])
  } else if (budgetType === QUARTERLY) {
    requiredFields = requiredFields.concat(['budgetYear'])
  } else if (budgetType === PHASE) {
    requiredFields = requiredFields.concat(['phaseSet'])
  }

  _applyErrors(formState, requiredFields, errors)

  return _getErrObj(errors)
}

const negativeAndPositiveIntegers = n => /^[-]?[0-9]+$/.test(n + '')

export const validateLegacyBudget = (formState, scope) => {
  let errors = {}
  let requiredFields = ['budgetType', 'amount']
  let isIntegers = /^\d+$/
  const { PHASE, MONTHLY, LIFE_OF_MATTER, QUARTERLY } = BUDGET_TYPES

  const budgetType = get(formState, 'budgetType.value')

  if (budgetType === MONTHLY) {
    requiredFields = requiredFields.concat(['budgetStartDate', 'budgetEndDate'])
  } else if (budgetType === LIFE_OF_MATTER) {
    requiredFields = requiredFields.concat(['budgetStartDate', 'budgetEndDate', 'matterId'])
  } else if (budgetType === QUARTERLY) {
    requiredFields = requiredFields.concat(['budgetYear'])
    if (!isIntegers.test(formState.budgetYear)) {
      errors.budgetYear = true
    }
  } else if (budgetType === PHASE) {
    requiredFields = requiredFields.concat(['phaseSet'])
  }

  if (!negativeAndPositiveIntegers(formState.amount)) {
    errors.amount = true
  }

  const MAX_DJANGO_BIG_INT = 9223372036854775807n
  const MIN_DJANGO_BIG_INT = -9223372036854775808n
  if (formState.amount * 100 > MAX_DJANGO_BIG_INT || formState.amount * 100 < MIN_DJANGO_BIG_INT) {
    errors.amount = true
  }

  _applyErrors(formState, requiredFields, errors, scope)

  return _getErrObj(errors)
}

export const validateReferral = formState => {
  let requiredFields = ['vendorId']
  let errors = {}

  if (formState.budgetRequested) {
    errors = validateBudgetRequest(formState).errors
  }

  _applyErrors(formState, requiredFields, errors)

  return _getErrObj(errors)
}
